import { Avatar, Box, Button, Flex, HStack, Spacer, Stack, Text, Tooltip } from '@chakra-ui/react'
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore'
import moment from 'moment'
import React, { useEffect, useMemo, useRef, useState } from 'react'

import ReactQuill, { Quill } from "react-quill";
import 'quill-image-uploader/dist/quill.imageUploader.min.css';
import { addDocumentFirebase, uploadBase64Firebase, uploadFileFirebase } from '../../Apis/firebaseApi'
import { auth, db } from '../../Config/firebase'
import { useGlobalState } from '../../Hooks/Contexts'
import useKanbanStore from '../../Hooks/Zustand/kanbanStore';
import parse from 'html-react-parser';
import RichTextEditor from '../Quill/RichTextEditor';
import DropboxUploader from '../Uppy/DropboxUploader';
import { useParams } from 'react-router-dom';
import { addActivity } from '../../Apis/logApi';


function TaskCardAddComment(props) {
	const quillRef = useRef();
	const globalState = useGlobalState()
	const { userDisplay } = useKanbanStore()
	const [isModalOpen, setModalOpen] = useState(false);
	const [shareLink, setShareLink] = useState("");

	const titleComment = props.title

	const [value, setValue] = useState("")
	const [data, setData] = useState()

	const param = useParams()


	const handleContentChange = (value) => {
		setValue(value);
	};

	function openModal() {
		setModalOpen(true);
	}

	function closeModal() {
		setModalOpen(false);
	}



	const handleShareLinkChange = (x) => {
		if (x !== "") {
			setShareLink({ link: x.link, type: x.type });
			const { link, type } = x;
			let htmlContent = '';

			if (type === 'image') {
				htmlContent = `<p><img src="${link}" alt="Image" width="500px" /></p><br/> <p>file: <a href=${link} rel="noopener noreferrer" target="_blank">${JSON.stringify(link)}</a></p>`;
			} else if (type === 'audio') {
				htmlContent = `<p><iframe class="ql-video" frameborder="0" allowfullscreen="true" src=${link}></iframe></p><br/> <p>file: <a href=${link} rel="noopener noreferrer" target="_blank">${JSON.stringify(link)}</a></p>`;
			} else if (type === 'video') {
				htmlContent = `<p><iframe class="ql-audio" frameborder="0" allowfullscreen="true" src=${link}></iframe></p><br/> <p>file: <a href=${link} rel="noopener noreferrer" target="_blank">${JSON.stringify(link)}</a></p>`;
			} else {
				htmlContent = `<p>file: <a href=${link} rel="noopener noreferrer" target="_blank">${JSON.stringify(link)}</a></p><br/> `;
			}


			setValue((prevContent) => prevContent + ` ${htmlContent}`);
		}

	};

	const handleSave = () => {
		if (!value) return
		addDocumentFirebase(`kanban/${props.id}/comments`, { comments: value, lastUpdated: new Date() }, globalState?.currentCompanies?.id)
			.then((x) => {
				setValue()
				handleLogActivity(props.id, x)
			})
	}

	const handleLogActivity = async (props, id) => {
		const data = {
			createdName: auth?.currentUser?.displayName || auth?.currentUser?.email || "anonymous",
			image: auth?.currentUser?.photoURL || "",
			createdBy: auth?.currentUser?.uid,
			type: "Task",
			companyId: globalState?.currentCompanies?.id,
			action: "Add Comment Task",
			collection_path: `kanban/${props}/comments`,
			collection_id: id,
			title: titleComment
		};


		try {
			const response = await addActivity(data); // Gunakan fungsi utilitas
			console.log(response, 'ini res');
		} catch (error) {
			console.log(error, 'ini error');
		}

	}


	useEffect(() => {
		if (globalState?.currentCompanies?.id) {
			const q = query(collection(db, `kanban/${props?.id}/comments`), where("companyId", "==", globalState?.currentCompanies?.id), orderBy('lastUpdated', 'desc'));
			const unsubscribe = onSnapshot(q, (querySnapshot) => {
				const qData = [];
				querySnapshot.forEach((doc) => {
					qData.push({ id: doc.id, ...doc.data() });
				});
				setData(qData);
			});
			return () => {
				unsubscribe()
			}
		}

	}, [globalState?.currentCompanies?.id])


	return (
		<Box borderBottom='1px' p='2' {...props}>

			{data ? data.map((x, i) =>
				<Flex m='2' key={i}>
					<Box m='2' p='2' borderRight='1px'>
						<Tooltip label={(userDisplay?.find((z) => z.id === x.createdBy))?.name || (userDisplay?.find((z) => z.id === x.createdBy))?.email} aria-label='Name'>
							<Avatar
								name={(userDisplay?.find((z) => z.id === x.createdBy))?.name || (userDisplay?.find((z) => z.id === x.createdBy))?.email}
								image={(userDisplay?.find((z) => z.id === x.createdBy))?.image}
							/>
						</Tooltip>
						<Text fontSize='2xs'>{moment.unix(x.lastUpdated.seconds).fromNow()}</Text>

					</Box>
					<Box>
						{/* <div
							dangerouslySetInnerHTML={{ __html: x.comments }}
						/> */}
						{parse(x?.comments, {
							replace: (domNode) => {
								if (domNode.type === 'text') {
									const textWithLinksReplaced = domNode.data.replace(
										/(\b(?:https?:\/\/|www\.)[^\s]+)/g,
										(match) => {
											const url = match.startsWith('http') ? match : `https://${match}`;
											return `<a href="${url}" target="_blank">${match}</a>`;
										}
									);
									return parse(textWithLinksReplaced);
								}
							},
						})}
					</Box>
					<Spacer />

				</Flex>)
				:
				<></>
			}

			<Stack>


				<RichTextEditor value={value} onChange={handleContentChange} />


				<HStack>
					{value !== "" && (
						<Button alignSelf='start' colorScheme='green' onClick={() => handleSave()}>Add Comment</Button>

					)}
					<Button onClick={openModal} colorScheme={'green'} variant='outline'>Upload</Button>
				</HStack>
			</Stack>

			<DropboxUploader isActive={isModalOpen} onClose={closeModal} parentPath={`/${globalState?.currentCompanies?.name}/kanban/${param?.id}`} shareLink={shareLink} setShareLink={handleShareLinkChange} />

		</Box>
	)
}

export default TaskCardAddComment