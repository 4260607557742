import { Box, Button, Container, FormControl, FormLabel, Heading, HStack, Input, Select, Textarea } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { addDocumentFirebase } from '../../Apis/firebaseApi'
import BackButtonComponent from '../../Components/Buttons/BackButtonComponent'
import { useGlobalState } from '../../Hooks/Contexts'

function AissistantHeadlinePage() {
	const globalState = useGlobalState()
	const [message,setMessage]=useState({})
	const [data,setData]=useState({template:'f4nUYjPvHUX5CloGE8NQ',type:'aissistant'})
	const navigate = useNavigate()
	

	const runAi=()=>{
		if(!message.topic || !message.language)
		return console.log(message)

	var str = '';
	for (const [p, val] of Object.entries(message)) {
		str += `${p.replace('_', ' ')}:\n -${val}\n`;
	}

	const newJSON= `Please create 30 headlines with json data below and use 119 examples as a reference. Please make sure each headlines has unique style\n \n ${str}`
	// return console.log(newJSON)
	 const newData=({
		 ...data,
		title:message.topic,
		message:[{role:"user",content:newJSON}]
	})
		addDocumentFirebase('files',newData,globalState.currentCompanies.id)
		.then((x)=>	navigate(`/aissistant/view/${x}`))
		.catch((err)=>console.log(err.message))
	}

  return (
	<Box>
		<HStack>
			<BackButtonComponent/>
			<Heading>Aissistant - Headline</Heading>
		</HStack>
		<Container>
		<FormControl>
			<FormLabel>Topic</FormLabel>
			<Input type='text' placeholder='Carwash' onChange={(e)=>setMessage({...message,topic:e.target.value})}/>
		</FormControl>
		<FormControl>
			<FormLabel>Benefits</FormLabel>
			<Textarea resize='none' onChange={(e)=>setMessage({...message,benefits:e.target.value})}  placeholder={`$100k/ month \n1.000 cars /month \nauto pilot business`} />
		</FormControl> 
		<FormControl>
			<FormLabel>Fears</FormLabel>
			<Textarea resize='none' onChange={(e)=>setMessage({...message,fears:e.target.value})}  placeholder={`Machine malfunctions \nRecruitments \nAfter sales`} />
		</FormControl> 
		<FormControl>
			<FormLabel>Time related</FormLabel>
			<Textarea resize='none' onChange={(e)=>setMessage({...message,time_related:e.target.value})}  placeholder={`Setup business in 30 days \nOnly takes 5 minutes to wash a car`} />
		</FormControl> 
		<FormControl>
			<FormLabel>Character/Brand related</FormLabel>
			<Textarea resize='none' onChange={(e)=>setMessage({...message,character_or_brand_competitor:e.target.value})}  placeholder={`Mr. Wash \n5 a sec`} />
		</FormControl> 
		<FormControl>
			<FormLabel>Location related</FormLabel>
			<Textarea resize='none' onChange={(e)=>setMessage({...message,location_related:e.target.value})}  placeholder={`Mr. Wash \n5 a sec\nJim Carry`} />
		</FormControl> 
		<FormControl>
			<FormLabel>Language</FormLabel>
			<Select placeholder='Language' onChange={(e)=>setMessage({...message,language:e.target.value})}>
				<option value='reply in english'>English</option>
				<option value='reply in indonesia'>Indonesia</option>
			</Select>
		</FormControl>

		<Button mt='2' onClick={()=>runAi()} w='full' colorScheme='green'>Run AI</Button>
		
		</Container>
	</Box>
  )
}

export default AissistantHeadlinePage
