import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { AddIcon, CloseIcon, DeleteIcon } from '@chakra-ui/icons';

import { FcFolder, FcPlus } from 'react-icons/fc';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { addDocumentFirebase, deleteDocumentFirebase, deleteSubcollection, getCollectionFirebaseV2 } from '../../Apis/firebaseApi';
import { useGlobalState } from '../../Hooks/Contexts';
import { collection, getDocs } from 'firebase/firestore';
import { auth, db } from '../../Config/firebase';
import { useRef } from 'react';
import { addActivity } from '../../Apis/logApi';
import BackButtonComponent from '../../Components/Buttons/BackButtonComponent';
import { clientTypesense } from '../../Apis/typeSense';

function AssetsPage() {
  const globalState = useGlobalState();
  const toast = useToast();
  const cancelRef = useRef();
  const navigate = useNavigate();

  const [dataSearched, setDataSearched] = useState([])
  const [searchInput, setSearchInput] = useState("")



  const [dataFolder, setDataFolder] = useState([]);
  const [dataNewFolder, setDataNewFolder] = useState({});
  const [modalFolder, setModalFolder] = useState(false);
  const [modal, setModal] = useState({ delete: false, user: false });
  const [selectedFolder, setSelectedFolder] = useState('');
  const [startIndex, setStartIndex] = useState(0);
  const itemsPerPage = 8;

  const fetchDataV2 = async () => {
    if (globalState?.currentCompanies?.id) {
      const conditions = [
        { field: 'companyId', operator: '==', value: globalState?.currentCompanies?.id },
        { field: 'type', operator: '==', value: 'digital_assets' },
        { field: 'users', operator: 'array-contains', value: globalState?.uid },
      ];
      const sortBy = { field: 'createdAt', direction: 'desc' };
      const limitValue = startIndex + itemsPerPage;

      try {
        const resAssets = await getCollectionFirebaseV2('folders', conditions, sortBy, limitValue);
        const projectDataArray = await Promise.all(
          resAssets.map(async (projectData) => {
            const userSnapshot = await getDocs(collection(db, `folders/${projectData?.id}/users`));
            const userData = userSnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            projectData.usersProjectData = userData;
            return projectData;
          })
        );

        setDataFolder(projectDataArray.length > 0 ? projectDataArray : []);
      } catch (error) {
        console.log(error);
      }
    }
  };


  const handleSearch = (text) => {
    if (text) {
      const newData = dataFolder.filter((item) => {
        const itemData = item.name
          ? item.name.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });


      setDataSearched(newData);
      setSearchInput(text);
    } else {
      setDataSearched([]);
      setSearchInput(text);
    }

  }

  const handleAddFolder = (e) => {
    const { value, name } = e.target;
    setDataNewFolder((prev) => ({ ...prev, [name]: value.toLowerCase() }));
  };

  const handleAddNewFolder = async () => {
    const companyId = globalState?.currentCompanies?.id;
    const dataUpdate = {
      ...dataNewFolder,
      company_name: globalState?.currentCompanies?.name,
      companyId,
      owner: [globalState?.uid],
      users: [globalState?.uid],
      category: 'folder',
      type: 'digital_assets',
    };

    const collectionName = 'folders';

    try {
      const docID = await addDocumentFirebase(collectionName, dataUpdate, companyId);

      if (docID) {
        const collectionNameFolder = `folders/${docID}/users`;
        const dataUser = globalState?.user;


        try {
          await addDocumentFirebase(collectionNameFolder, dataUser, companyId);
          toast({
            title: 'Success',
            description: 'Succes to add new folder.',
            isClosable: true,
            duration: 9000,
            status: 'success',
          });
          fetchDataV2();
          setModalFolder(false);
        } catch (error) {
          console.log('Terjadi kesalahan:', error);
        }

      }
      handleLogActivity(docID, dataUpdate)
    } catch (error) {
      console.log(error, 'ini error');
    }
  };

  const handleLogActivity = async (id, data) => {
    const dataActive = {
      createdName: auth?.currentUser?.displayName || auth?.currentUser?.email || "anonymous",
      image: auth?.currentUser?.photoURL || "",
      createdBy: auth?.currentUser?.uid,
      type: "Digital Assets",
      companyId: globalState?.currentCompanies?.id,
      action: "Add New Folder",
      collection_path: `folders`,
      title: data.name,
      id: id
    };

    try {
      const response = await addActivity(dataActive); // Gunakan fungsi utilitas
      console.log(response, 'ini res');
    } catch (error) {
      console.log(error, 'ini error');
    }
  }

  const handleDeleteFolder = async () => {
    try {
      const res = await deleteSubcollection(`folders/${selectedFolder?.id}/users`);

      if (res) {
        const resDeleteFolder = await deleteDocumentFirebase(`folders`, selectedFolder?.id);
        if (resDeleteFolder) {
          handleLogActivities(selectedFolder)
          setSelectedFolder('');
          setModal({ delete: false, user: false });
          toast({
            title: 'Success',
            description: resDeleteFolder,
            status: 'success',
            position: 'top-right',
            isClosable: true,
          });
          fetchDataV2();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleLogActivities = async (x) => {
    const dataActive = {
      createdName: auth?.currentUser?.displayName || auth?.currentUser?.email || "anonymous",
      image: auth?.currentUser?.photoURL || "",
      createdBy: auth?.currentUser?.uid,
      type: "Digital Assets",
      companyId: globalState?.currentCompanies?.id,
      action: "Delete Folder",
      collection_path: `folders`,
      title: x.name,
      id: x.id
    };

    try {
      const response = await addActivity(dataActive); // Gunakan fungsi utilitas
      console.log(response, 'ini res');
    } catch (error) {
      console.log(error, 'ini error');
    }
  }



  const handleLoadMore = () => {
    setStartIndex((prev) => prev + itemsPerPage);
  };

  useEffect(() => {
    if (globalState?.currentCompanies?.id) {
      fetchDataV2();
    }
  }, [globalState?.currentCompanies?.id, startIndex]);

  return (
    <Stack p={[1, 1, 5]} spacing={5}>
      <HStack>
        <BackButtonComponent />
        <Heading size={'md'}>Folder</Heading>
        <Spacer />
        <HStack>
          <Stack>
            <Input shadow={'md'} bgColor='white' placeholder='Search' size={'md'} borderRadius='md' onChange={(e) => handleSearch(e.target.value)} />
          </Stack>
          <Button onClick={() => setModalFolder(true)} bgColor={'white'} shadow='md' variant='outline' borderColor='#F05A28' color='#F05A28'>
            <HStack>
              <FcPlus />
              <Text>New Folder</Text>
            </HStack>
          </Button>
        </HStack>
      </HStack>

      {dataFolder?.length > 0 && searchInput === "" ? (
        <SimpleGrid columns={[1, 2, 4]} gap={5}>
          {dataFolder.map((x, index) => (
                 <Flex
                 key={index}
                 justify={'space-between'}
                 borderRadius={'md'}
                 alignItems={'flex-start'}
                 borderWidth={1}
                 bgColor='white'
                 shadow={'md'}
                 borderColor='blackAlpha.300'
                 cursor='pointer'
                 w='100%'
                 onClick={() => navigate(`/assets/${x.company_name}/${x.name}`, { state: x })}
               >
                 <HStack spacing={3} w='100%'>
                   <Stack py={4} pl={4} >
                     <FcFolder size={50} />
                   </Stack>
                   <Stack w='100%'>
                     <Text
                      textTransform={'capitalize'} color='blackAlpha.800' pt={5} noOfLines={2} fontSize={'sm'} fontWeight={'medium'}
                     >
                       {x.name}
                     </Text>
                     <AvatarGroup size='sm' mx={10} pb={4} pr={4}>
                       {x?.usersProjectData?.map((z, index) => (
                         <Avatar key={index} name={z.name} src={z.name} />
                       ))}
                     </AvatarGroup>
                   </Stack>
                 </HStack>
                 {x?.owner?.includes(globalState?.uid) && (
                   <Box pt={4} w='15%'>
                     <DeleteIcon onClick={() => {
                       setModal({ delete: true, user: false });
                       setSelectedFolder(x);
                     }} />
                   </Box>
                 )}
               </Flex>
          ))}
        </SimpleGrid>
      ) : (
        dataSearched.length > 0 ? (
          <SimpleGrid columns={[1, 2, 4]} gap={4}>
            {
              dataSearched.map((x, index) => {

                return (
                  <Flex
                  key={index}
                  justify={'space-between'}
                  borderRadius={'md'}
                  alignItems={'flex-start'}
                  borderWidth={1}
                  bgColor='white'
                  shadow={'md'}
                  borderColor='blackAlpha.300'
                  cursor='pointer'
                  w='100%'
                  onClick={() => navigate(`/assets/${x.company_name}/${x.name}`, { state: x })}
                >
                  <HStack spacing={3} w='100%'>
                    <Stack py={4} pl={4} >
                      <FcFolder size={50} />
                    </Stack>
                    <Stack w='100%'>
                      <Text
                       textTransform={'capitalize'} color='blackAlpha.800' pt={5} noOfLines={2} fontSize={'sm'} fontWeight={'medium'}
                      >
                        {x.name}
                      </Text>
                      <AvatarGroup size='sm' mx={10} pb={4} pr={4}>
                        {x?.usersProjectData?.map((z, index) => (
                          <Avatar key={index} name={z.name} src={z.name} />
                        ))}
                      </AvatarGroup>
                    </Stack>
                  </HStack>
                  {x?.owner?.includes(globalState?.uid) && (
                    <Box pt={4} w='15%'>
                      <DeleteIcon onClick={() => {
                        setModal({ delete: true, user: false });
                        setSelectedFolder(x);
                      }} />
                    </Box>
                  )}
                </Flex>
                )
              })
            }
          </SimpleGrid>
        ) : (
          <Stack minH={"80vh"} alignItems="center" justifyContent="center">
            <Text fontSize="lg" fontWeight={500} color="gray.500" >No data found</Text>
          </Stack>
        )
      )}

      <Stack alignItems={'center'} justifyContent='center' py={5}>
        <Box>
          {dataFolder?.length > startIndex && <Button onClick={handleLoadMore} size='sm'>Load More</Button>}
        </Box>
      </Stack>

      <Modal isOpen={modalFolder} onClose={() => setModalFolder(false)} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>New Folder</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={1}>
              <Stack>
                <Input name='name' type='text' placeholder='Name Folder' onChange={handleAddFolder} />
              </Stack>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Flex gap={5}>
              <Button
                leftIcon={<AddIcon boxSize={3} />}
                colorScheme='green'
                onClick={() => handleAddNewFolder()}
              >
                Add Folder
              </Button>
              <Button
                leftIcon={<CloseIcon boxSize={3} />}
                colorScheme='red'
                onClick={() => {
                  setModalFolder(false);
                }}
              >
                Cancel
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AlertDialog
        isOpen={modal?.delete}
        leastDestructiveRef={cancelRef}
        onClose={() => setModal({ delete: false, user: false })}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Delete Folder
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure want to delete folder '<b>{selectedFolder?.name}</b>'? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setModal({ delete: false, user: false })}>
                Cancel
              </Button>
              <Button colorScheme='red' onClick={() => handleDeleteFolder()} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Modal onClose={() => setModal({ ...modal, user: false })} isOpen={modal?.user} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Modal Title</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <Heading size={'sm'}>Editor</Heading>
              {dataFolder?.length > 0 && dataFolder?.map((item, index) => (
                <HStack key={index}>
                  {item?.usersProjectData?.map((z, i) => (
                    <Stack>
                      <Avatar name={z.name} src={z.name} />
                      <Text>{z.name}</Text>
                    </Stack>
                  ))}
                </HStack>
              ))}
              <Heading size={'sm'}>Visitor</Heading>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => setModal({ ...modal, user: false })}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
}

export default AssetsPage;
