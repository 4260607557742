import React from 'react'
import LoginPage from '../Pages/Authentication/LoginPage'
import SigunUpPage from '../Pages/Authentication/SignUpPage'
import ForgotPasswordPage from '../Pages/Authentication/ForgotPasswordPage';
import ClosePage from '../Pages/Close/ClosePage';


const AuthenticationRouter = [
	{
		path: '/',
		element: <ClosePage />,
	},

	// {
	// 	path: '/',
	// 	element: <LoginPage />,
	// },
	// {
	// 	path: '/login',
	// 	element: <LoginPage />,
	// },
	// {
	// 	path: '/signup',
	// 	element: <SigunUpPage />,
	// },
	// {
	// 	path: '/forgotpass',
	// 	element: <ForgotPasswordPage />,
	// },

];

export default AuthenticationRouter