import {
	useDisclosure, Box, Button, Input, Select, InputLeftAddon, InputGroup, Avatar, Tooltip, HStack, Text, Flex, Heading, Stack, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, Slider, SliderTrack, SliderFilledTrack, SliderThumb, Spacer
} from '@chakra-ui/react'
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import useKanbanStore from '../../Hooks/Zustand/kanbanStore';
import { addDocumentFirebase, setDocumentFirebase } from '../../Apis/firebaseApi';
import TaskCardAddComment from '../../Components/Cards/TaskCardAddComment';
import { useGlobalState } from '../../Hooks/Contexts';
import { auth, db } from '../../Config/firebase';
import TaskHistoryComponent from '../../Components/Cards/TaskHistoryComponent';
import { doc, onSnapshot } from 'firebase/firestore';
import { addActivity } from '../../Apis/logApi';
import { IoMdClose } from 'react-icons/io';


const TaskViewPage = () => {

	const param = useParams()
	const id = param.taskId
	const globalState = useGlobalState()
	const [data, setData] = useState()
	const [showHistory, setShowHistory] = useState(false)
	const [dataSubmit, setDataSubmit] = useState({})
	const [asignee, setAsignee] = useState([])
	const [pic, setPic] = useState([])
	const [category, setCategory] = useState({ open: false })
	const [addCategory, setAddCategory] = useState()
	const [mainData, setMainData] = useState()
	const navigate = useNavigate()

	const [value, setValue] = useState(0)

	const handleChange = (value) => setValue(value)



	const capitalizeFirstLetter = (string) => {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	const handleEditData = (type, value) => {
		const newData = data
		if (type === 'dueOn') {
			value = moment(value).unix();
		}
		newData[type] = value
		setDataSubmit({ ...newData })
	}

	const saveCategory = () => {
		let catData = []
		if (category?.name)
			catData = category.name

		handleEditData('category', addCategory)
		catData.push(addCategory)

		setDocumentFirebase('files', param.id, { category: catData })
			.then((x) => console.log(x))
			.catch((err) => console.log(err.message))

		setCategory({ name: catData, open: false })

	}

	const handleSave = () => {
		setDocumentFirebase('kanban', id, {
			...dataSubmit, asignee: asignee, pic,
			score: value !== 0 ? value : data?.score ? data?.score : mainData.default_score || 0,
		})
			.then((x) => {
				handleLogActivity(dataSubmit)
				navigate(-1)
			})
			.catch((err) => console.log(err.message))
	}

	const handleLogActivity = async (dataSubmit) => {
		const data = {
			createdName: auth?.currentUser?.displayName || auth?.currentUser?.email || "anonymous",
			image: auth?.currentUser?.photoURL || "",
			createdBy: auth?.currentUser?.uid,
			type: "Task",
			companyId: globalState?.currentCompanies?.id,
			action: "Add New Task",
			collection_path: `kanban`,
			title: dataSubmit.title,
			score: value
		};

		try {
			const response = await addActivity(data); // Gunakan fungsi utilitas
			console.log(response, 'ini res');
		} catch (error) {
			console.log(error, 'ini error');
		}

	}

	useEffect(() => {
		//onsnapshot here...
		const unsubCategory = onSnapshot(doc(db, "files", param.id), (doc) => {
			if (doc.data()?.category) {
				setCategory({ name: doc.data().category, open: false })
				setMainData(doc.data())
			}
			else {
				setCategory({ open: false })
				setMainData()
			}
		});

		const unsubTask = onSnapshot(doc(db, "kanban", param.taskId), (doc) => {
			setData(doc.data())
			setAsignee(doc.data()?.asignee ? doc.data()?.asignee : [])
			setPic(doc.data()?.pic ? doc.data()?.pic : [])
		});



		return () => {
			unsubTask()
			unsubCategory()
			setCategory()
		}
	}, [])




	return (
		<Stack bg='red' w='100%' >
			{data ?
				<Stack bg='white' p={10}>

					<HStack justifyContent='space-between' alignItems={"center"}>
						<Stack spacing={1}>
							<Heading size='md'>{data?.title}</Heading>
							<Text fontSize='3xs'> ID: {param.taskId}</Text>

						</Stack>
						<Spacer />
						<HStack spacing={5}>
							<Text fontWeight={800} fontSize="lg">Score Card : {data?.score || 0}</Text>
							<Button colorScheme={"red"} size="md" onClick={() => navigate(-1)}>
								<IoMdClose />
							</Button>
						</HStack>
					</HStack>


					<Box>
						<Input type='text' placeholder={data?.title} onChange={(e) => handleEditData('title', e.target.value)} />

						<InputGroup m='1'>
							<InputLeftAddon children='Last Updated' />
							<Input disabled type='text' value={
								moment.unix(data?.lastUpdated?.seconds).format('DD/MM/YYYY') ?? moment().format('yyyy-MM-DD')
							} />

						</InputGroup>
						<InputGroup m='1'>
							<InputLeftAddon children='Created By' />
							<Input
								disabled
								type='text'
								value={
									mainData?.usersDisplay?.find((x) => x.id === data.createdBy)?.name
								}
							/>
						</InputGroup>


						<InputGroup m='1'>
							<InputLeftAddon children='Category' />

							<Select placeholder='category'
								defaultValue={data?.category ? data.category : <></>}
								onChange={(e) => handleEditData('category', e.target.value)}>
								{category?.name?.sort()?.map((x, i) =>
									<option key={i} value={x}>{x}</option>
								)}
							</Select>

							{category?.open ?
								<></>
								:
								<Button colorScheme='green' onClick={() => {
									setCategory({ ...category, open: true })
								}}>+</Button>
							}
						</InputGroup>

						{category?.open ?
							<HStack>
								<InputGroup m='1'>
									<InputLeftAddon children='Category' placeholder='category name' />
									<Input type='text' onChange={(e) => setAddCategory(e.target.value)} />
								</InputGroup>
								<Button colorScheme='green' onClick={() => saveCategory()}>Save</Button>
								<Button colorScheme='red' onClick={() => setCategory({ ...category, open: false })}>x</Button>
							</HStack>
							:
							<></>}

						{asignee?.length > 0 ?
							<InputGroup m='1'>
								<InputLeftAddon children='Assigned to' />
								<HStack ml='1' gap='0'>

									{[...new Set(asignee)]?.sort()?.map((x, i) => {
										let name = mainData?.usersDisplay?.find((y) => y.id === x)?.name
										return <Box key={i} >
											<Tooltip label={name} aria-label='Name'>
												<Avatar onClick={() => {
													const newAsingee = asignee.filter((z) => z !== x)
													setAsignee(newAsingee)
												}}
													size='sm'
													name={name}
													src={name}
												/>
											</Tooltip>
										</Box>
									})}
								</HStack>
							</InputGroup>
							:
							<></>
						}

						<InputGroup m='1'>
							<InputLeftAddon children='Assign to' />
							<Select width='full' placeholder='assign to' onChange={(e) => {
								setAsignee([...asignee, e.target.value])
							}}>
								{mainData?.usersDisplay ?
									mainData?.usersDisplay?.sort((a, b) => a?.name?.localeCompare(b?.name))
										?.map((x, i) => <option key={i} value={x.id}>{x.name ? capitalizeFirstLetter(x.name) : x.email}</option>)
									:
									data?.usersDisplay?.usersDisplay?.sort((a, b) => a?.name?.localeCompare(b?.name))
										?.map((x, i) => <option key={i} value={x.id}>{x.name ? capitalizeFirstLetter(x.name) : x.email}</option>
										)
								}
							</Select>
						</InputGroup>

						{pic?.length > 0 ?
							<InputGroup m='1'>
								<InputLeftAddon children='PIC to' />
								<HStack ml='1' gap='0'>

									{[...new Set(pic)]?.sort()?.map((x, i) => {
										let name = mainData?.usersDisplay?.find((y) => y.id === x)?.name

										return <Box key={i} >
											<Tooltip label={name} aria-label='Name'>
												<Avatar onClick={() => {
													const newAsingee = pic.filter((z) => z !== x)
													setPic(newAsingee)
												}}
													size='sm'
													name={name}
													src={name}
												/>
											</Tooltip>
										</Box>
									})}
								</HStack>
							</InputGroup>
							:
							<></>
						}

						<InputGroup m='1'>
							<InputLeftAddon children='PIC' />
							<Select width='full' placeholder='users' onChange={(e) => {
								setPic([...pic, e.target.value])
							}}>
								{mainData?.usersDisplay ?
									mainData?.usersDisplay?.sort((a, b) => a?.name?.localeCompare(b?.name))
										?.map((x, i) => <option key={i} value={x.id}>{x.name ? capitalizeFirstLetter(x.name) : x.email}</option>)
									:
									data?.usersDisplay?.usersDisplay?.sort((a, b) => a?.name?.localeCompare(b?.name))
										?.map((x, i) => <option key={i} value={x.id}>{x.name ? capitalizeFirstLetter(x.name) : x.email}</option>
										)
								}
							</Select>
						</InputGroup>


						<InputGroup m='1'>
							<InputLeftAddon children='Label' />
							<Select
								onChange={(e) => handleEditData('label', e.target.value)}
								defaultValue={data?.label} placeholder='Label'>
								<option value='help'>help</option>
								<option value='pending'>pending</option>
								<option value='blocked'>blocked</option>
								<option value='completed'>completed</option>
							</Select>
						</InputGroup>

						<InputGroup m='1'>
							<InputLeftAddon children='Due On' />
							<Input type='date'
								onChange={(e) => handleEditData('dueOn', e.target.value)}
								defaultValue={
									data?.dueOn ?
										moment.unix(data?.dueOn).format('yyyy-MM-DD')
										:
										moment(new Date()).format('yyyy-MM-DD')
								}
							/>
						</InputGroup>
					</Box>

					<Text fontWeight={500}>Score Input: </Text>
					<Flex>
						<NumberInput maxW='100px' mr='2rem' min={0} max={10} value={value} onChange={handleChange}>
							<NumberInputField />
							<NumberInputStepper>
								<NumberIncrementStepper />
								<NumberDecrementStepper />
							</NumberInputStepper>
						</NumberInput>
						<Slider
							flex='1'
							focusThumbOnChange={false}
							value={value}
							min={0}
							max={10}
							onChange={handleChange}
						>
							<SliderTrack>
								<SliderFilledTrack />
							</SliderTrack>
							<SliderThumb fontSize='sm' boxSize='32px' children={value} />
						</Slider>
					</Flex>

					<Flex bg='white'>
						<Box flex='1'>
							<TaskCardAddComment id={id} title={data?.title} />
						</Box>

						{showHistory ?
							<Box maxW='xs' bg='white' p={2}>
								<TaskHistoryComponent setShowHistory={setShowHistory} color='blackAlpha.800' id={id} />
							</Box>
							: <Button
								size='xs'
								variant='ghost'
								colorScheme='green'
								onClick={() => setShowHistory(!showHistory)}>{showHistory ? 'Hide History' : 'Show History'}
							</Button>}

					</Flex>



					<Flex w='100%' justifyContent='flex-end'>
						<Button colorScheme='blue' mr={3} onClick={() => handleSave()}>
							Save Card
						</Button>
					</Flex>

				</Stack>
				:
				<></>
			}
		</Stack>
	)


	// return <Navigate to='/kanban' replace />
}

export default TaskViewPage