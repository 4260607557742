import { Box, Heading, HStack,Tabs, TabList, TabPanels, Tab, TabPanel, Flex, Button, Text, Icon, Center, Input, Image, Spacer, Stack  } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { FcMultipleDevices } from 'react-icons/fc'
import { Link, useParams } from 'react-router-dom'
import { addDocumentFirebase, arrayUnionFirebase, getSingleDocumentFirebase } from '../../Apis/firebaseApi'
import BackButtonComponent from '../../Components/Buttons/BackButtonComponent'
import { useGlobalState } from '../../Hooks/Contexts'

function FunnelsViewPage() {
	const globalState=useGlobalState()
	const [data,setData]=useState()
	const [page,setPage]=useState()
	const param = useParams()

	const handlePage=(id)=>{
		getSingleDocumentFirebase(`funnels/${param.id}/page`,id)
		.then((x)=>setPage(x))
	}

	const handleAddFunnel=()=>{
		addDocumentFirebase(`funnels/${param.id}/page`,{title:'New Funnel'},globalState.currentCompanies.id)
		.then((id)=>{
			arrayUnionFirebase('funnels',param.id,'funnels',[id])
			.then((x)=>console.log(x))
			.catch((err)=>console.log(err.message))
			})
		.catch((err)=>console.log(err.message))
	}

	const getFunnel = ()=>{
		getSingleDocumentFirebase('funnels',param.id)
		.then((x)=>setData(x))
		.catch((err)=>console.log(err.message))
	}


	useEffect(() => {
		getFunnel()
	
	  return () => {
		
	  }
	}, [])
	


  return (
	<Box>
		<HStack>
			<BackButtonComponent/>
			<Heading>{data?.title}</Heading>
		</HStack>
		<Flex borderRadius='md' shadow='base' m='5' p='2' minH='80vh'>
			<Box borderRight='1px' p='5' >
				<Heading fontSize='md' p='2'>Pages</Heading>
				{data?.funnels?.map((z,i)=>
					<HStack  key={i} borderTop='1px' p='2' onClick={()=>handlePage(z)}>
						<Icon as={FcMultipleDevices}/>
						<Text>{z}</Text>
					</HStack>
				)}
				<Center>
					<Button colorScheme='green' onClick={()=>handleAddFunnel()}>Add new page</Button>
				</Center>
			</Box>
			<Stack  p='5' overflowY='auto' w='full' >
				{page?
					page.data?
					<Box>
						<HStack>
							<Text>{page.title}</Text>
							<Spacer/>
							<Text>Option #1</Text>
							<Text>Option #2</Text>
							<Text>Option #3</Text>
						</HStack>
						<HStack>
							<Input placeholder='www.domain.com' w='full'/>
							<Spacer/>	
							<Button colorScheme='blue' mt='2'><a href='asd'>Edit</a></Button>
							<Button colorScheme='blue' mt='2'>View</Button>
						</HStack>
						<Stack >
						<Image p='5' mt='5' shadow='base' 
						// onMouseOver={()=>}
						src='https://bloggofullpage.files.wordpress.com/2020/10/screencapture-ringly-2020-10-02-10_32_23-edit.png?w=1200' 
						alt='website'/>
						{/* {html?<div dangerouslySetInnerHTML={{ __html: html.data}} />:<></>} */}
						
						</Stack>
					</Box>
					:
					<Stack justifyContent='center' alignItems='center'>
					<Heading>Create new</Heading>
					<HStack>
						<Link to={`useAi/${page.id}`}>
							<Button colorScheme='blue'>Use AI</Button>
						</Link>
						{/* <Button colorScheme='blue'>Use Templates</Button> */}
						<Button colorScheme='yellow'>Start from scratch</Button>
					</HStack>
					</Stack>
				:
				<Stack alignItems='center' >
					<Heading>Please select your funnel page</Heading>
				</Stack>
				}
			</Stack	>

		</Flex>
	</Box>
  )
}

export default FunnelsViewPage