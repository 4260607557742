import React from 'react'
import MindMapPage from '../Pages/MindMap/MindMapPage'
import ListMindmap from '../Pages/MindMap/ListMindmap'
import MindMapIndex from '../Pages/MindMap/MindMapIndex'
import MindmapV2 from '../Pages/MindmapV2/MindmapV2'
import MindMapV3 from '../Pages/MindmapV3/MindMapViewPage'
// import MindMapViewPage from '../Pages/MindMap/MindMapViewPage'


const MindMapRouter = [
	{
		path: "/mindmap",
		element: <ListMindmap/>
	},
	{
		path: "/create-mindmap",
		element: <MindMapPage />
	},
	{
		path: "/mindmapv2",
		element: <MindmapV2/>
	},
	{
		path: "/mindmapv3",
		element: <MindMapV3/>
	}
	
]

export default MindMapRouter