import {
	Avatar, AvatarGroup, Badge, Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Box, Button, Center, chakra, Container, Heading, HStack, Input, Select, SimpleGrid, Stack, Text, useBreakpointValue, useDisclosure, Spacer, Tooltip, filter
} from '@chakra-ui/react'
import { collection, doc, onSnapshot, query } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import { useNavigate, useParams } from 'react-router-dom'
import { addDocumentFirebase, arrayUnionFirebase } from '../../Apis/firebaseApi'
import { clientTypesense } from '../../Apis/typeSense'
import KanbanColumnsComponent from '../../Components/Columns/KanbanColumnsComponent'
import { auth, db } from '../../Config/firebase'
import { useGlobalState } from '../../Hooks/Contexts'
import useKanbanStore from '../../Hooks/Zustand/kanbanStore'
import CountdownTimer from '../../Components/Timer/CountdownTimer'
import moment from 'moment'
import { addActivity } from '../../Apis/logApi'
import BackButtonComponent from '../../Components/Buttons/BackButtonComponent'


function KanbanViewPage() {
	const [kanbanData, setKanbanData] = useState()
	const [search, setSearch] = useState({ status: false })
	const [searchResult, setSearchResult] = useState()
	const { isOpen, onOpen, onClose } = useDisclosure()
	const isDesktop = useBreakpointValue({ base: false, lg: true })
	const param = useParams()
	const navigate = useNavigate()
	const stateX = useKanbanStore()

	const updateUserDisplay = useKanbanStore((state) => state.setUserDisplay)
	const { userDisplay, setTaskData, setFilterData, filterData, resetFilterData } = useKanbanStore()

	const globalState = useGlobalState()
	const ColumnType = {
		BACKLOG: 'backlog',
		TODOS: 'todos',
		PROGRESS: 'progress',
		REVIEW: 'review',
		DONE: 'done',
	}

	const chunkArray = (arr, chunkSize) => {
		const chunks = [];
		for (let i = 0; i < arr.length; i += chunkSize) {
			chunks.push(arr.slice(i, i + chunkSize));
		}
		return chunks;
	};

	const handleSearchUsers = (q) => {
		const companyUsers = globalState.companies.find((x) => x.id === globalState.currentCompanies.id);
		const userChunks = chunkArray(companyUsers?.users, 100)


		const searchPromises = userChunks.map((userChunk) => {
			const searchParameters = {
				q: q,
				query_by: "name,email",
				filter_by: `id: [${userChunk.join(",")}]`,
				sort_by: "_text_match:desc"
			};

			return clientTypesense
				.collections("users")
				.documents()
				.search(searchParameters);
		});



		Promise.all(searchPromises)
			.then((results) => {
				const combinedResults = results.flatMap((result) => result.hits);
				setSearchResult(combinedResults);
			})
			.catch((error) => {
				console.error("Error performing search:", error);
			});
	};

	const capitalizeFirstLetter = (string) => {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	const handleNewCard = async () => {
		setTaskData(kanbanData)
		addDocumentFirebase('kanban', {
			column: 'todos',
			title: 'New Card',
			filesId: param.id,
			lastUpdated: new Date(),
			dueOn: moment().unix(),
		}, globalState.currentCompanies.id).then(docId => {
			setTaskData({
				index: 0,
				columnsData: [],
				setData: function () { },
				task: {
					id: docId,
					title: 'New Card',
					asignee: [],
					column: 'todos',
					filesId: param.id,
					kanbanData,
					companyId: globalState.currentCompanies.id,
					createdAt: new Date(),
					lastUpdated: new Date(),
					dueOn: moment().unix(),
				}
			})
			navigate(docId, { state: { type: 'newCard' } })
		})
		// console.log(kanbanData)
	}

	const handleAddUser = (param, x) => {
		arrayUnionFirebase('files', param.id, 'users', [x.document.id])
		arrayUnionFirebase('files', param.id, 'usersDisplay', [{ ...x.document }])
		setSearchResult()
		setSearch()

		handleLogActivity(param.id, x)


	}

	const handleLogActivity = async (id, user) => {
		const data = {
			createdName: auth?.currentUser?.displayName || auth?.currentUser?.email || "anonymous",
			image: auth?.currentUser?.photoURL,
			createdBy: auth?.currentUser?.uid,
			type: "kanban",
			companyId: globalState?.currentCompanies?.id,
			action: "invite",
			collection_path: "files",
			collection_id: id,
			from: auth?.currentUser?.displayName,
			to: user.document.name,
		};

		try {
			const response = await addActivity(data); // Gunakan fungsi utilitas
			console.log(response, 'ini res');
		} catch (error) {
			console.log(error, 'ini error');
		}

	}

	useEffect(() => {
		let unsub = () => { }

		if (param.id !== "my-kanban") {
			unsub = onSnapshot(
				doc(db, "files", param.id),
				(doc) => {
					setKanbanData(doc.data())
					updateUserDisplay(doc.data()?.usersDisplay)
				});
		} else {

		}
		return () => {
			unsub()
			setKanbanData()
			setSearch()
		}
	}, [])

	return (
		<>
			<Box>

				<HStack>
					<BackButtonComponent />

					<Box>
						<Heading textAlign='center' size={"lg"}>Kanban {param.title}</Heading>
						<Text fontSize='2xs' color={"gray.500"}>ID :{param.id}</Text>
					</Box>
					<Spacer />


					{filterData?.asignee !== '' || filterData?.label !== '' || filterData?.category !== '' ? <CountdownTimer seconds={60} /> : null}

					<Spacer />

					<>
						<AvatarGroup size='sm' gap='1' max={4}>
							{userDisplay ?
								userDisplay.map((x, i) =>
									<Avatar key={i} name={x.name} />
								)
								:
								<></>
							}
						</AvatarGroup>
						<Button size='sm' colorScheme='green' onClick={onOpen}>+</Button>
					</>
				</HStack>

				<SimpleGrid gap={2} columns={5} bgColor='white' p='2' m='1' shadow='base'>
					<Select value={filterData?.asignee} placeholder='Assignee' onChange={(e) => {
						let updateData = []
						if (filterData?.asignee?.length > 0)
							updateData = filterData.asignee
						updateData.push(e.target.value)
						setFilterData({ ...filterData, asignee: updateData })
						// console.log({ ...filterData, asignee: updateData }, "filterDataa")
					}}>
						{kanbanData?.usersDisplay?.length > 0 ?
							kanbanData.usersDisplay.sort((a, b) => a?.name?.localeCompare(b?.name))?.map((x, index) => {
								return (<option key={index} value={x.id}>
									{x.name ? capitalizeFirstLetter(x.name) : x.email}
								</option>)
							}
							)
							:
							<></>}
					</Select>

					<Select value={filterData?.category} placeholder='Category' onChange={(e) => {
						// console.log({ ...filterData, category: e.target.value }, 'filter category')
						setFilterData({ ...filterData, category: e.target.value })
					}}>
						{kanbanData?.category ?
							kanbanData?.category?.sort((a, b) => capitalizeFirstLetter(a)?.localeCompare(capitalizeFirstLetter(b)))?.map((x, i) =>
								<option key={i} value={x}>{capitalizeFirstLetter(x)}</option>
							)
							:
							<></>}
					</Select>

					<Select value={filterData?.label} placeholder='Label' onChange={(e) => {
						setFilterData({ ...filterData, label: e.target.value })
						// console.log({ ...filterData, label: e.target.value })
					}}>
						<option>help</option>
						<option>pending</option>
						<option>blocked</option>
						<option>completed</option>
					</Select>
					<Input type='text' placeholder='search' value={filterData?.search} onChange={(e) =>
						setFilterData({ search: e.target.value })} />
					<Button onClick={() => {
						// navigate('new-card', { state: { type: "new" } })
						// resetTaskData()
						handleNewCard()
					}}
						colorScheme='green'
					>+ New Card</Button>

				</SimpleGrid>

				{filterData?.asignee || filterData?.category || filterData?.label ?
					<HStack>
						{filterData?.asignee ?
							<Box p='2' m='2' bgColor='white' borderRadius='md' shadow='base'>
								<Text textAlign='center'>Asignee</Text>
								<HStack>
									{filterData.asignee.map((x) =>
										<Avatar size='2xs' marginRight='-2' name={x.name} src={x.image ? x.image : <></>} />

									)}
								</HStack>
							</Box>
							:
							<></>
						}
						{filterData?.category ?
							<Box p='2' m='2' bgColor='white' borderRadius='md' shadow='base'>
								{/* <Text position='fixed' top='0' right='2'>x</Text> */}
								<Text textAlign='center'>Category</Text>
								<Text textAlign='center'>{capitalizeFirstLetter(filterData?.category)}</Text>
							</Box>
							:
							<></>
						}
						{filterData?.label ?
							<Box p='2' m='2' bgColor='white' borderRadius='md' shadow='base'>

								<Text textAlign='center'>Label</Text>
								<Text textAlign='center'>{capitalizeFirstLetter(filterData?.label)}</Text>

							</Box>
							:
							<></>
						}
						{filterData?.search ?
							<Box p='2' m='2' bgColor='white' borderRadius='md' shadow='base'>
								<Text textAlign='center'>Search</Text>
								<Text textAlign='center'>{capitalizeFirstLetter(filterData?.search)}</Text>
							</Box>
							:
							<></>
						}
						<Spacer />
						{/* <Button minW='min-content' colorScheme='green'>Search</Button> */}
						{/* <Button minW='min-content' colorScheme='green' onClick={() => console.log(filterData)}>check console</Button> */}
						<Button minW='min-content' colorScheme='red' onClick={() => {
							// setFilterData({
							// 	asignee : '',
							// 	category : '',
							// 	label : ''
							// })
							resetFilterData()
						}}
						>Clear</Button>
					</HStack>
					:
					<></>}
				<DndProvider backend={HTML5Backend}>
					{isDesktop ?
						<SimpleGrid
							columns={{ base: 1, md: 5 }}
						>
							<KanbanColumnsComponent kanbanData={kanbanData} allowedDropEffect='move' filterData={filterData} column={ColumnType.TODOS} />
							<KanbanColumnsComponent kanbanData={kanbanData} allowedDropEffect='move' filterData={filterData} column={ColumnType.BACKLOG} />
							<KanbanColumnsComponent kanbanData={kanbanData} allowedDropEffect='move' filterData={filterData} column={ColumnType.PROGRESS} />
							<KanbanColumnsComponent kanbanData={kanbanData} allowedDropEffect='move' filterData={filterData} column={ColumnType.REVIEW} />
							<KanbanColumnsComponent kanbanData={kanbanData} allowedDropEffect='move' filterData={filterData} column={ColumnType.DONE} />
						</SimpleGrid>
						:
						<Center>
							<Heading>Desktop View Only</Heading>
						</Center>
					}
				</DndProvider>


			</Box>



			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Add User</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<HStack m='1'>
							<Input type='text' placeholder='Search users' onChange={(e) => handleSearchUsers(e.target.value)} />
						</HStack>
						{searchResult?.length > 0 ?
							searchResult?.map((x) => {
								if (kanbanData?.users.find((z) => z !== x.document.id)) {
									return (
										<HStack p='2' borderBottom='1px'>
											<Avatar name={x.document.name} src={x.document.image ? x.document.image : ''} />
											<Box>
												<Text>{x.document.name}</Text>
												<Text>{x.document.email}</Text>
											</Box>
											<Spacer />
											<Button colorScheme='green' onClick={() => {
												handleAddUser(param, x)
											}}>+</Button>
										</HStack>
									)
								}
							})
							: <></>}
					</ModalBody>

					{/* <ModalFooter>
						<Button colorScheme='blue' mr={3} onClick={onClose}>
							Close
						</Button>
					</ModalFooter> */}
				</ModalContent>
			</Modal>
		</>
	)
}

export default KanbanViewPage