import { Stack } from "@chakra-ui/react";
import { useEffect } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useLocation, useNavigate } from "react-router-dom";
import ProgressCircle from "./Components/Progress/ProgressCircle";
import { auth } from "./Config/firebase";
import { useGlobalState } from "./Hooks/Contexts";
import Layout from "./Layouts";
import VerifyEmail from "./Pages/Authentication/VerifyEmail";
import ClosePage from "./Pages/Close/ClosePage";
import ChatPageFirst from "./Pages/Messanger/ChatPageFirst";
import AuthRouter from "./Router/AuthRouter";
// import FunnelViewRouter from "./Router/FunnelViewRouter";
import MainRouter from "./Router/MainRouter";

function App() {
  const globalState = useGlobalState();

  const location = useLocation();

  const navigate = useNavigate();



  useEffect(() => {
    document.title = "Productivity";

    if (location.pathname !== "/") {
      navigate("/");
    }

    const metaTag = document.querySelector('meta[name="description"]');
    if (metaTag) {
      metaTag.content =
        "Productivity is a platform to simplify your bussiness affair.";
    }


    // const faviconLink = document.querySelector("link[rel='icon']");
    // faviconLink.href =
    //   "https://www.dropbox.com/scl/fi/kqljyi02yq77h2yfr2zug/2-2.png?rlkey=4v9qrswmcr47bapem2z3cnt2d&dl=0&raw=1";

    return () => {};
  }, [globalState?.user?.emailVerified]);

  return (
    <Stack position={"relative"} overflow="hidden">
      {/* {globalState.isLoggedin ? (
        auth?.currentUser?.emailVerified ? (
          <Stack>
            <MainRouter />
          </Stack>
        ) : (
          <VerifyEmail />
        )
      ) : ( */}
      <AuthRouter />
      {/* )
      } */}

      <Stack position={"absolute"} bottom={5} right={5}>
        <ChatPageFirst
          module={"productivity"}
          companyId={
            globalState?.currentCompanies?.id === "VuzPVXr2Crsd9iQxmjHk"
              ? "hNlk1cVXJGX8tLEdEO7b"
              : "8NCG4Qw0xVbNR6JCcJw1"
          }
          projectId={
            globalState?.currentCompanies?.id === "VuzPVXr2Crsd9iQxmjHk"
              ? "hNlk1cVXJGX8tLEdEO7b"
              : "8NCG4Qw0xVbNR6JCcJw1"
          }
          companyName={globalState?.currentCompanies?.name}
        />
      </Stack>
    </Stack>
  );
}

export default App;
