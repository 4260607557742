import React, { useCallback, useEffect, useRef, useState } from "react"
import ReactFlow, {
	Controls,
	Panel,
	useStoreApi,
	useReactFlow,
	ReactFlowProvider,
	ConnectionLineType
} from "reactflow"
import { shallow } from "zustand/shallow"

// import useStore from "./store"
// import MindMapNode from "./MindMapNode"
// import MindMapEdge from "./MindMapEdge"

// import "./index.css"

// we need to import the React Flow styles to make it work
import "reactflow/dist/style.css"
// import MindMapNode from "../../Components/MindMap/MindMapNode"
// import MindMapEdge from "../../Components/MindMap/MindMapEdge"
// import useMindMapStore from "../../Hooks/Zustand/mindMapStore"
import { Button, Stack } from "@chakra-ui/react"
import { onValue, ref } from "firebase/database"
import { rtdb } from "../../Config/firebase"
import MindMapNode from "../../Components/MindMapV3/MindMapNode"
import MindMapEdge from "../../Components/MindMapV3/MindMapEdge"
import useMindMapStore from "../../Hooks/Zustand/mindMapStore"

const selector = state => ({
	nodes: state.nodes,
	edges: state.edges,
	setInitialNodes: state.setInitialNodes,
	setInitialEdges: state.setInitialEdges,
	onNodesChange: state.onNodesChange,
	onEdgesChange: state.onEdgesChange,
	onDrop: state.onDrop,
	addChildNode: state.addChildNode
})

const nodeTypes = {
	mindmap: MindMapNode
}

const edgeTypes = {
	mindmap: MindMapEdge
}

const nodeOrigin = [0.5, 0.5]
const randomColor = Math.floor(Math.random() * 16777215).toString(16);

const connectionLineStyle = { stroke: randomColor, strokeWidth: 5 }
const defaultEdgeOptions = { style: connectionLineStyle, type: "mindmap" }

function Flow() {
	// whenever you use multiple values, you should use shallow for making sure that the component only re-renders when one of the values change
	const { nodes, onDrop, setInitialNodes, setInitialEdges, edges, onNodesChange, onEdgesChange, addChildNode } = useMindMapStore(
		selector,
		shallow
	)
	const connectingNodeId = useRef(null)
	const store = useStoreApi()
	const { project } = useReactFlow()


	const getChildNodePosition = (event, parentNode) => {
		const { domNode } = store.getState()

		if (
			!domNode ||
			// we need to check if these properites exist, because when a node is not initialized yet,
			// it doesn't have a positionAbsolute nor a width or height
			!parentNode?.positionAbsolute ||
			!parentNode?.width ||
			!parentNode?.height
		) {
			return
		}

		const { top, left } = domNode.getBoundingClientRect()

		// we need to remove the wrapper bounds, in order to get the correct mouse position
		const panePosition = project({
			x: event.clientX - left,
			y: event.clientY - top
		})

		// we are calculating with positionAbsolute here because child nodes are positioned relative to their parent
		return {
			x: panePosition.x - parentNode.positionAbsolute.x + parentNode.width / 2,
			y: panePosition.y - parentNode.positionAbsolute.y + parentNode.height / 2
		}
	}

	const onConnectStart = useCallback((_, { nodeId }) => {
		connectingNodeId.current = nodeId
	}, [])

	const onConnectEnd = useCallback(
		event => {
			const { nodeInternals } = store.getState()
			const targetIsPane = event.target.classList.contains("react-flow__pane")
			const node = event.target.closest(".react-flow__node")

			if (node) {
				node.querySelector("input")?.focus({ preventScroll: true })
			} else if (targetIsPane && connectingNodeId.current) {
				const parentNode = nodeInternals.get(connectingNodeId.current)
				const childNodePosition = getChildNodePosition(event, parentNode)

				if (parentNode && childNodePosition) {
					addChildNode(parentNode, childNodePosition)
				}
			}
		},
		[getChildNodePosition]
	)

	const handleRTDB = (data) => {
		const starCountRef = ref(rtdb, `8NCG4Qw0xVbNR6JCcJw1/mindmap/mindmapId/${data}`);
		onValue(starCountRef, (snapshot) => {
			const datas = snapshot.val();
			if (datas) {
				if (data === 'nodes')
					setInitialNodes(datas)
				else
					setInitialEdges(datas)
			}
			else {
				if (data === 'nodes') {
					setInitialNodes([
						{
							id: "root",
							type: "mindmap",
							data: { label: "React Flow Mind Map" },
							position: { x: 0, y: 0 }
						}
					])
				}
				else setInitialEdges([])
			}
		});
	}

	useEffect(() => {
		handleRTDB('nodes')
		handleRTDB('edges')
		return () => {
		}
	}, [])

	return (
		<Stack width='99wh' h='99vh'>
			{/* <Button onClick={() => console.log(initialState('nodes'))}>Check data state</Button> */}
			<ReactFlow
				nodes={nodes}
				edges={edges}
				onNodesChange={onNodesChange}
				onEdgesChange={onEdgesChange}
				nodeTypes={nodeTypes}
				edgeTypes={edgeTypes}
				onConnectStart={onConnectStart}
				onConnectEnd={onConnectEnd}
				// nodeOrigin={nodeOrigin}
				connectionLineStyle={connectionLineStyle}
				defaultEdgeOptions={defaultEdgeOptions}
				connectionLineType={ConnectionLineType.Straight}
				// onDrop={onDrop}
				onNodeDragStop={onDrop}
				onnode
				// onPaneMouseMove={(e) => console.log(e)}
				// onEdgeMouseMove={(e) => console.log(e)}
				mouse
				// onPaneMouseMove={(e) => console.log(e.clientX, e.clientY, 'moving on panel')}
				fitView
			>
				<Controls showInteractive={false} />
				<Panel position="top-left">Mind Map</Panel>
				<Panel position="top-right">Login kek apaan kek di sini</Panel>
			</ReactFlow>
		</Stack>
	)
}

export default function MindMapV3 () {
    return(
        <ReactFlowProvider>
            <Flow />
        </ReactFlowProvider>

    )
    }