import {
	Box,
	Button,
	Checkbox,
	Container,
	Divider,
	FormControl,
	FormLabel,
	Heading,
	HStack,
	Image,
	Input,
	Stack,
	Text,
	useToast,
} from '@chakra-ui/react'
import logo from '../../Assets/Image/1.png'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../Config/firebase';
// import { OAuthButtonGroup } from './OAuthButtonGroup'
// import { PasswordField } from './PasswordField'

function ForgotPasswordPage() {
	const navigate = useNavigate();
	const toast = useToast();
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState('');

	const handleSubmit = async () => {
		setLoading(true)
		sendPasswordResetEmail(auth, email)
			.then(() => {
				toast({
					title: 'Email sent to ' + email,
					description: "You can reset your password on the link we've sent you. If the email doesn't exist, check Spam/Trash section, otherwise please make sure you typed in correct email address",
					status: 'success',
					duration: 9000,
					isClosable: true
				})
				setLoading(false);
				navigate('/')
			})
			.catch((error) => {
				toast({
					title: 'An error happened',
					description: error.message,
					status: 'error',
					duration: 9000,
					isClosable: true
				})
				setLoading(false);
			}).finally(() => {
				setLoading(false);
			});
	};


	return (
		<Container
			maxW="lg"
			py={{
				base: '12',
				md: '24',
			}}
			px={{
				base: '0',
				sm: '8',
			}}
		>
			<Stack spacing="8">
				<Stack spacing="6">
					<Stack
						spacing={{
							base: '2',
							md: '3',
						}}
						textAlign="center"
					>
						<Heading
							size={{
								base: 'xs',
								md: 'sm',
							}}
						>
							<Stack alignItems={'center'} justifyContent='center'>
								<Image
									w="200px"
									borderRadius={20}
									src={logo}
									alt="Alternate Text"
								/>
							</Stack>
						</Heading>
						<HStack spacing="1" justify="center">
							<Text color="muted">Don't have an account?</Text>
							<Button variant="link" colorScheme="blue" onClick={() => navigate('/signup')}>
								Sign up
							</Button>
						</HStack>
					</Stack>
				</Stack>
				<Box
					py={{
						base: '0',
						sm: '8',
					}}
					px={{
						base: '4',
						sm: '10',
					}}
					bg={{
						base: 'transparent',
						sm: 'bg-surface',
					}}
					boxShadow={{
						base: 'none',
						sm: 'md',
					}}
					borderRadius={{
						base: 'none',
						sm: 'xl',
					}}
				>
					<Stack spacing="6">
						<Stack spacing="5">
							<FormControl>
								<FormLabel htmlFor="email">Email</FormLabel>
								<Input id="email" type="email" onChange={e => setEmail(e.target.value)} />
							</FormControl>
						</Stack>

						<Stack spacing="6">
							<Button
								variant="primary"
								onClick={handleSubmit}
								loadingText="Processing.."
								disabled={loading}
								isLoading={loading}
							>
								Reset Password
							</Button>
						</Stack>
					</Stack>
				</Box>
			</Stack>
		</Container>
	)
}






export default ForgotPasswordPage