import { Box, Button, Heading,  Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton, HStack, Input, SimpleGrid, Spacer, Text, useDisclosure, Center, Icon, AspectRatio, Stack } from '@chakra-ui/react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { navigate } from 'react-big-calendar/lib/utils/constants'
import { Link, useNavigate } from 'react-router-dom'
import { getCollectionFirebase } from '../../Apis/firebaseApi'
import { clientTypesense } from '../../Apis/typeSense'
import CircleComponent from '../../Components/Shapes/CircleComponent'
import DiamondComponent from '../../Components/Shapes/DiamondComponent'
import OuvalComponent from '../../Components/Shapes/OuvalComponent'
import ParallelogramComponent from '../../Components/Shapes/ParallelogramComponent'
import RectangleComponent from '../../Components/Shapes/RectangleComponent'
import { useGlobalState } from '../../Hooks/Contexts'
import { aissistantTemplates } from './aisistantDataTemplate'

import {AiOutlineAlert} from 'react-icons/ai'

function AisisstantPage() {
	const globalState = useGlobalState()
	const { isOpen, onOpen, onClose } = useDisclosure()
	const [data,setData]=useState([])
	const navigate=useNavigate()


	const getData=(date)=>{
		    const conditions = [
				{ field: "createdBy", operator: "==", value: globalState.uid },
				{ field: "type", operator: "==", value: "aissistant" },
			];
	    const sortBy = { field: "lastUpdated", direction: "asc" };
		getCollectionFirebase('files',{conditions},{sortBy},{limitValue:9},{startAfterData:date})
		.then((x)=>{
			if(data.length>1)
			setData([...data,...x])
			else
			setData(x)

		})
		.catch((err)=>console.log(err.message))
	}

	const handleSearchUsers = (q) => {
		console.log(q)
		if(!q)
		return getData(99999999999)


		const searchParameters = {
			q: q,
			query_by: "title",
			filter_by: `type: aissistant && createdBy:${globalState.uid} && companyId:${globalState.currentCompanies.id}`,
			sort_by: "_text_match:desc"
		};
		clientTypesense
			.collections("files")
			.documents()
			.search(searchParameters)
			.then((x) => {
				console.log(x,'ini x')
				const hits = x.hits.map((x)=>x.document)
				setData(hits)
			})
			.catch((err)=>console.log(err.message))
	}

	useEffect(() => {
	  getData(9999999999)
	
	  return () => {
		setData()
	  }
	}, [])
	

  return (
	  <>
	<Stack alignItems={'center'} justifyContent='center' h={'700px'}>
		<Stack>
			<AiOutlineAlert size={100} />
		</Stack>
		<Text size={'md'} color='gray.800' textTransform={'uppercase'} fontSize={15} fontWeight={500}>Now you can access Aissistant only on</Text>
		<Text size={'sm'} color='gray.600' textTransform={'uppercase'} fontSize={20} fontWeight='bold' cursor={'pointer'} onClick={() => window.open('https://ai.deoapp.com'," _blank")}>ai.deoapp.com</Text>
		{/* <HStack>
		<Heading>Aissistant</Heading>
		<Spacer/>
			<Button colorScheme='green' onClick={()=>navigate('new')}>Add new AI</Button>
		</HStack>

		<Input m='2' type='text' placeholder='Search Files' onChange={(e)=>handleSearchUsers(e.target.value)}/>
		<SimpleGrid columns={3} gap='2'>
			{data?.map((x)=><Box shadow='base' p='2'>
				<Link to={`view/${x.id}`}>
					<HStack>
					{x.template?<Icon as={aissistantTemplates.find((z)=>z.id===x.template).icon} color={aissistantTemplates.find((z)=>z.id===x.template).iconColor} boxSize={12}/>:<></>}
					<Box>
						<Text>{x.title}</Text>
						{x.template?<Text fontSize='2xs'>Type: {aissistantTemplates.find((z)=>z.id===x.template).name}</Text> :<></>}
						<Text fontSize='2xs'>ID: {x.id}</Text>
						<Text fontSize='2xs'>lastUpdated: {moment.unix(x?.lastUpdated?.seconds).fromNow()}</Text>
					</Box>
					</HStack>
				</Link>
			</Box>)}
		</SimpleGrid>
		<Center>
			{
				data?.length%9===0?
					<Button  mt='2' colorScheme='green' onClick={()=>getData(data[data.length-1].lastUpdated)}>Load more</Button>
					:
					<></>
			}
		</Center> */}
	</Stack>


	<Modal size='5xl' isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* <Lorem count={2} /> */}
			<SimpleGrid columns='4'>
			{/* {templates.map((x)=><Text>{x.name}</Text>)} */}
			</SimpleGrid>

          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant='ghost'>Secondary Action</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
	</>
  )
}

export default AisisstantPage