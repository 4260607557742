import { Box, Button, FormControl, FormLabel, Grid, HStack, Heading, Input, InputGroup, InputRightElement, Stack, Text, useToast } from '@chakra-ui/react'
import { sendEmailVerification, updatePassword, updateProfile } from 'firebase/auth';
import React, { useState } from 'react'
import { setDocumentFirebase } from '../../Apis/firebaseApi';
import { auth } from '../../Config/firebase';
import ToastComponent from '../../Components/ToastComponent';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';

function VerifyEmail() {
	const [emailSent, setEmailSent] = useState(false);
	const [name, setName] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [isVerifying, setIsVerifying] = useState(false);

	const toast = useToast();

	const verify = () => {
		if (!name) {
			toast({
				title: "Oops!",
				description: "Name cannot be blank, please input your name",
				status: "error",
				duration: 9000,
				isClosable: true,
				position: 'top'
			})
		} else if (name && !password) {
			toast({
				title: "Oops!",
				description: "Password cannot be blank, please input your password",
				status: "error",
				duration: 9000,
				isClosable: true,
				position: 'top'
			})
		} else if (name && password && !confirmPassword) {
			toast({
				title: "Oops!",
				description: "Confirm password cannot be blank",
				status: "error",
				duration: 9000,
				isClosable: true,
				position: 'top'
			})
		} else if (name && password && confirmPassword && password !== confirmPassword) {
			toast({
				title: "Oops!",
				description: "Password and confirm password cannot be different",
				status: "error",
				duration: 9000,
				isClosable: true,
				position: 'top'
			})
		} else {
			setIsVerifying(true)
			updateProfile(auth.currentUser, {
				displayName: name, photoURL: ""
			  }).then(() => {
				// Profile updated!
				// ...
			  }).catch((error) => {
				// An error occurred
				// ...
			  });
			sendEmailVerification(auth.currentUser)
				.then(() => {
					setEmailSent(true)
					setDocumentFirebase('users',
						auth.currentUser.uid,
						{
							name: name,
							email: auth.currentUser.email
						}
					).then(() => {
						updatePassword(auth.currentUser, password).then(() => {
							// Update successful.
							
						}).catch((error) => {
							// An error ocurred
							// ...
							toast({
								title: "Oops!",
								description: error.message,
								status: "error",
								duration: 9000,
								isClosable: true,
								position: 'top'
							})
						});
					}).finally(()=>{
						setIsVerifying(false)
					});
				});
		};
	};

	return (
		<Stack alignItems='center' justifyContent='center'>
			<Heading size='lg' my={20}>Please verify your email address to continue</Heading>
			{/* <Grid templateColumns='1' gap={2}>
				<HStack>
					<Text>Name: </Text>
					<Input type='text' onChange={(e) => setName(e.target.value)} placeholder='enter your name' />
				</HStack>
				<HStack>
					<Text>Password: </Text>
					<Input type='password' onChange={(e) => setPassword(e.target.value)} placeholder='enter your name' />
				</HStack>
				<HStack>
					<Text>Confirm Password: </Text>
					<Input type='password' onChange={(e) => setConfirmPassword(e.target.value)} placeholder='enter your name' />
				</HStack>
			</Grid>
			<Text>email address: {auth.currentUser.email}</Text>
			<Button onClick={() => verify()} colorScheme='green'>Click to verify</Button>
			{emailSent ? <Text>Email sent, please check your email at {auth.currentUser.email}</Text> : <></>} */}


			<Stack w='xl' spacing={4} bg='white' p='10' borderRadius='3xl' shadow='md'>
				<Heading textAlign='center' size='md'>Email address: {auth.currentUser.email}</Heading>

				<HStack>
					<FormControl id="lastName">
						<FormLabel>Name</FormLabel>
						<Input placeholder='Enter your name' onChange={e => setName(e.target.value)} type="text" />
					</FormControl>
				</HStack>


				<FormControl id="password" isRequired>
					<FormLabel>Password</FormLabel>
					<InputGroup>
						<Input placeholder='Enter your new password' onChange={e => setPassword(e.target.value)} type={showPassword ? 'text' : 'password'} />
						<InputRightElement h={'full'}>
							<Button
								variant={'ghost'}
								onClick={() =>
									setShowPassword((showPassword) => !showPassword)
								}>
								{showPassword ? <ViewIcon /> : <ViewOffIcon />}
							</Button>
						</InputRightElement>
					</InputGroup>
				</FormControl>


				<FormControl id="confirm_password" isRequired>
					<FormLabel>Confirm Password</FormLabel>
					<InputGroup>
						<Input onChange={e => setConfirmPassword(e.target.value)} placeholder='Confirm your new password' type={showConfirmPassword ? 'text' : 'password'} />
						<InputRightElement h={'full'}>
							<Button
								variant={'ghost'}
								onClick={() =>
									setShowConfirmPassword((showConfirmPassword) => !showConfirmPassword)
								}>
								{showConfirmPassword ? <ViewIcon /> : <ViewOffIcon />}
							</Button>
						</InputRightElement>
					</InputGroup>
				</FormControl>


				<Stack spacing={10} pt={2}>
					<Button
						onClick={() => verify()}
						isLoading={isVerifying}
						loadingText="Verifying email, please wait..."
						size="lg"
						bg={'green.400'}
						color={'white'}
						_hover={{
							bg: 'green.600',
						}}>
						Click to Verify Email
					</Button>
				</Stack>

				{emailSent ? <Heading size='sm'>Email sent, please check your email at {auth.currentUser.email}</Heading> : <></>}
			</Stack>
		</Stack>
	)
}

export default VerifyEmail