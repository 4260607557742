import { Heading, Image, Stack, Text, Link, HStack, Spacer } from '@chakra-ui/react'
import React from 'react'
import { FcAdvertising } from 'react-icons/fc';
import logo from "../../Assets/Image/1.png"

function ClosePage() {

    const handleWhatsapp = () => {
        const message = encodeURIComponent(`Hi Admin Deoapp,\n\nSilahkan tinggalkan pesanmu, dan Team Support kami akan segera membalas 😊`);
        const source = encodeURIComponent(window.location.href);
        const url = `https://api.whatsapp.com/send?phone=6281241678382&text=${message}%0A%0ASource:%20${source}`;
        window.open(url, '_blank');
    }
    return (
        <Stack w={"full"} h="90vh" alignItems={"center"} justifyContent="center">
            <Stack w={["100%", "90%", "50%"]} spacing={5} p={5} >
                <Image src={logo} alt="https://business.deoapp.com" w={"200px"} />

                <HStack spacing={3}>
                    <FcAdvertising size={40} />

                    <Heading>Important: We've Moved!</Heading>

                </HStack>
                <Text>
                    Dear Visitor,
                    This page is no longer active. We've relocated to our new website: business.deoapp.com.
                    Please click the link below to access our latest content and services:
                </Text>
                <Link fontStyle={"italic"} color="blue.600" href={"https://business.deoapp.com"} fontWeight={"bold"} cursor="pointer">Visit Our New Website</Link>
                <Text>For any questions or assistance, contact us at <Link fontStyle={"italic"} color="blue.600" cursor="pointer">info@deoapp.com</Link> or <Link fontStyle={"italic"} color="blue.600" cursor="pointer" onClick={handleWhatsapp}>Call Whatsapp</Link>.</Text>
                <Text>Thank you for your support!

                </Text>
                <Text>Best Regards,</Text>
                <br />
                <Spacer />
                <Text fontWeight={"bold"}>Deoapp Indonesia</Text>
            </Stack>
        </Stack>
    )
}

export default ClosePage