import axios from "axios";
import {
  getCollectionFirebase,
  getSingleDocumentFirebase,
} from "../../Apis/firebaseApi";
import { decryptToken } from "../../Utils/encrypToken";

export async function LoginUser(dispatch, loginPayload) {
  const { email, password } = loginPayload;
  try {
    dispatch({ type: "REQUEST_LOGIN" });
    // const response = await axios.post("https://reqres.in/api/login", {
    //   email,
    //   password
    // });
    const response = await axios.post("https://reqres.in/api/login", {
      email,
      password,
    });
    console.log(response);
    dispatch({ type: "LOGIN_SUCCESS", payload: response.data });
    localStorage.setItem("currentUser", JSON.stringify(response.data));
    return response.data;
  } catch (error) {
    dispatch({ type: "LOGIN_ERROR", error: error });
  }
}

export async function getProjects(dispatch, uid) {
  const newData = [];

  const conditions = [
    { field: "users", operator: "array-contains", value: uid },
  ];

  getCollectionFirebase(
    "companies",
    { conditions },
    { sortBy: null },
    { limitValue: null },
    { startAfterData: null }
  ).then((x) => {
    x?.map((y) => newData.push({ id: y.id, name: y.name, users: y.users }));
    dispatch({
      type: "COMPANIES",
      payload: {
        companies: newData,
      },
    });
    currentCompanies(dispatch, uid, newData[0]);
    // localStorage.setItem("currentCompanies", JSON.stringify(newData[0]));
  });

  const currentCompanies = (dispatch, uid, firstCompanies) => {

    const fetchCompanyId = localStorage.getItem("currentCompanies");
    const companyId = JSON.parse(fetchCompanyId)

    getSingleDocumentFirebase("users", uid).then((x) => {
      dispatch({
        type: "USER",
        payload: {
          user: x,
        },
      });

      dispatch({
        type: "CURRENT_COMPANIES",
        payload: {
          currentCompanies: companyId ? companyId : firstCompanies,
        },
      });
    });

    getSingleDocumentFirebase("token", "dropbox").then((x) => {

      const token = decryptToken(x?.access_token)
      dispatch({
        type: "ACCESS_TOKEN",
        payload: {
          access_token: token,
        },
      })
    });
  };
}
