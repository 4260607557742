import { Box, Button, Flex, Heading, HStack, Img, Stack, Text } from '@chakra-ui/react'
import { HiChevronRight } from 'react-icons/hi'

export const HeroeWithImageBackground = () => {
	return (
		<Box bg="gray.800" as="section" minH="140px" position="relative">
			<Box py="32" position="relative" zIndex={1}>
				<Box
					maxW={{
						base: 'xl',
						md: '7xl',
					}}
					mx="auto"
					px={{
						base: '6',
						md: '8',
					}}
					color="white"
				>
					<Box maxW="xl">
						<Heading as="h1" size="3xl" fontWeight="extrabold">
							Build relationship with the your customers
						</Heading>
						<Text
							fontSize={{
								md: '2xl',
							}}
							mt="4"
							maxW="lg"
						>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
							incididunt ut labore et dolore magna aliqua.
						</Text>
						<Stack
							direction={{
								base: 'column',
								md: 'row',
							}}
							mt="10"
							spacing="4"
						>
							<Button
								as="a"
								href="#"
								colorScheme="blue"
								px="8"
								rounded="full"
								size="lg"
								fontSize="md"
								fontWeight="bold"
							>
								Get Started for Free
							</Button>
							<HStack
								as="a"
								transition="background 0.2s"
								justify={{
									base: 'center',
									md: 'flex-start',
								}}
								href="#"
								color="white"
								rounded="full"
								fontWeight="bold"
								px="6"
								py="3"
								_hover={{
									bg: 'whiteAlpha.300',
								}}
							>
								<span>Talk to Sales</span>
								<HiChevronRight />
							</HStack>
						</Stack>
					</Box>
				</Box>
			</Box>
			<Flex
				id="image-wrapper"
				position="absolute"
				insetX="0"
				insetY="0"
				w="full"
				h="full"
				overflow="hidden"
				align="center"
			>
				<Box position="relative" w="full" h="full">
					<Img
						src="https://images.unsplash.com/photo-1590650153855-d9e808231d41?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=2250&q=80"
						alt="Main Image"
						w="full"
						h="full"
						objectFit="cover"
						objectPosition="top bottom"
						position="absolute"
					/>
					<Box position="absolute" w="full" h="full" bg="blackAlpha.600" />
				</Box>
			</Flex>
		</Box>
	)
}